import {Component, useState} from "react";

import {Button, Card, ConfigProvider, Form, Input, Layout, message, Row, Select, Space} from "antd";
import Apiurl from "../ConstURL";
import {ArrowLeftOutlined, CloseOutlined, RollbackOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import Header from "../components/Header";


export default function Authpage() {


    const [form] = Form.useForm()
    const Navigate = useNavigate()

    const onFinish = (values) => {
        var token = localStorage.getItem("token")

        fetch(Apiurl + "/tickets/add/type?" + new URLSearchParams({
            token: token,
            typename: values.username
        }))
            .then(response => {
                return response.json()

            }).then(data => {
                console.log(data)
                if (data['Status']) {
                    message.success("Тип добавлен!", 9)
                    form.resetFields()
                    // e.preventDefault();
                    // e.target.reset();

                } else {
                    message.error("Ошибка!")
                }
            }
        )

        //window.alert("Авторизация прошла успешно!")
    };

    const validatePhone = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^((\+7|7|8)+([0-9]){10})$/
            );
    };
    const validatePhoneNumber = (rule, value) => {
        return new Promise(async (resolve, reject) => {
            if (!value) {
                //await reject('Введите телефон!')
            } else if (!/^((\+7|7|8)+([0-9]){10})$/.test(value)) {
                await reject('Это не номер телефона')
            } else {
                await resolve()
            }
        })
    }
    const validateEmailAvailable = (rule, value) => {
        return new Promise(async (resolve, reject) => {
            var token = localStorage.getItem("token")


            if (!value) {
                //await reject('Введите почту!')
            }
                // else if (!/^((\+7|7|8)+([0-9]){10})$/.test(value)) {
                //     await reject('Это не номер телефона')
            // }
            else {
                const Check = await fetch(Apiurl + "/admin/available?token=" + token + "&email=" + value)
                    .then((response) => response.json()
                    ).then((json) => {
                        return json
                    })
                if (Check['available']) {
                    await resolve()
                } else {
                    await reject('Почта занята!')
                }

            }
        })
    }


    const [Createduser, setCreated] = useState(false)
    const [validphone, setvalidphone] = useState("error")

    if (!Createduser) {


        return (<div>
                <Header/>
                <Row type="flex" justify="center" align="middle" style={{minHeight: '50vh', paddingTop: '20px'}}>
                    <Card type="flex" title={"Добавить тип"} justify="center" align="middle" extra={<Button shape={"circle"} onClick={(e)=>Navigate('/tickettypes')} size="large"  icon={<RollbackOutlined/>}></Button>}>


                        {/*<Button type='primary' size='large' shape='round' onClick={(e) => setCreated(!Createduser)}>Создать</Button>*/}
                        {/*Create User*/}
                        <Form
                            layout='vertical'
                            style={{justifyContent: 'center'}}
                            name="basic"
                            form={form}
                            wrapperCol={{
                                span: '75%',
                            }}
                            style={{
                                width: '325px',
                                verticalAlign: 'middle',
                                maxWidth: '75%',

                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            // onFinishFailed={}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Название"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите название!',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{

                                    span: 30,
                                }}
                            >
                                <Button type="primary" htmlType="submit" size='large'>
                                    Добавить тип
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Row>
            </div>
        );
    } else {
        return (

            <Space direction="horizontal" style={{width: '100%', justifyContent: 'center', paddingTop: '25px'}}>
                <Button type='primary' size='large' shape='round'
                        onClick={(e) => setCreated(!Createduser)}>Создать</Button>
                !!!!!
            </Space>

        )
    }

}