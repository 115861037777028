import {Component, useEffect, useState} from "react";
import CountUp from 'react-countup';
import {
    Button,
    Card,
    Flex,
    Pagination,
    Row,
    Typography,
    Space,
    Progress,
    message,
    Statistic,
    Input,
    Select, Collapse, Image, Form, Divider
} from "antd";
import Apiurl from "../ConstURL";

import {
    CheckOutlined, CloseOutlined,
    FileExcelOutlined, MinusOutlined,
} from '@ant-design/icons';

import Header from "../components/Header";
import {useAuth} from "../funcs/Authcontext";
import {useParams} from "react-router-dom";
import {format} from "date-fns";



export default function Mainpage() {

    const [tasks, settasks] = useState([])
    const [types, settypes] = useState([])

    const [form] = Form.useForm()
    let params = useParams()

    const formatter = (value: number) => <CountUp end={value} separator=" " />;

    useEffect(()=>{
        fetch(Apiurl + "/contractors/get/ticket?" + new URLSearchParams({
            token: params.tickettoken,
            ticketid: params.ticketid

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                settasks(data.Data)
            })


        fetch(Apiurl + "/contractors/get/type?" + new URLSearchParams({
            token: params.tickettoken,
            ticketid: params.ticketid

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                settypes(data.Data)
            })
    },[]);

    const onFinish = (values) => {
        var token = localStorage.getItem("token")

        fetch(Apiurl + "/tickets/add/type?" + new URLSearchParams({
            token: token,
            typename: values.username
        }))
            .then(response => {
                return response.json()

            }).then(data => {
                console.log(data)
                if (data['Status']) {
                    message.success("Тип добавлен!", 9)
                    form.resetFields()
                    // e.preventDefault();
                    // e.target.reset();

                } else {
                    message.error("Ошибка!")
                }
            }
        )

        //window.alert("Авторизация прошла успешно!")
    };


    return (<div>
            <Flex vertical={false} justify={"center"} style={{paddingTop: "25px"}}>

                <Space type="flex" size='large' justify="center" align="middle" direction="vertical"
                       style={{paddingTop: "20px",paddingBottom:"20px"}}>

                    {tasks.map((ticket, index) => (
                        <Card title={"Информация о запросе"} style={{width: "95vw"}}>
                            <Flex vertical={true} gap={"small"}>
                                <div style={{wordBreak: "break-word"}}><b>ID:</b> {params.ticketid}</div>






                                <Collapse
                                    style={{Width: "100%", marginTop: "15px"}}
                                    items={[{
                                        key: 1,
                                        label: "Основной текст",
                                        children:
                                            <div>{ticket.message_text}</div>
                                    }
                                    ]}/>
                                {((ticket.dop_text.length) > 0) ? <Collapse items={[{
                                    key: 1,
                                    label: "Дополнительный текст",
                                    children:
                                        <Flex vertical={true} gap={"small"}>
                                            {ticket.dop_text.map((dop_mes, index_dop) => (
                                                <div><a>@</a> : {dop_mes.text}
                                                </div>))}
                                        </Flex>
                                }]}></Collapse> : <div></div>}

                                {((ticket.images.length) > 0) ? <Collapse items={[{
                                    key: 1,
                                    label: "Фото",
                                    children:
                                        <Image.PreviewGroup>
                                            {ticket.images.map((photos, index_dop) => (
                                                <Image width={100}
                                                       src={Apiurl + "/contractors/files/" + photos + "?token=" + params.tickettoken+"&ticketid="+params.ticketid}/>
                                            ))}
                                        </Image.PreviewGroup>

                                }]}></Collapse> : <div></div>}

                                {((ticket.documents.length) > 0) ? <Collapse items={[{
                                    key: 1,
                                    label: "Документы",
                                    children:
                                        <Flex>
                                            {ticket.documents.map((document, index_dop) => (
                                                <a style={{wordBreak: "break-word"}}
                                                    target={"_blank"}
                                                   href={Apiurl + "/contractors/files/" + document + "?token=" + params.tickettoken+"&ticketid="+params.ticketid}>
                                                    {document}
                                                </a>
                                            ))}
                                        </Flex>

                                }]}></Collapse> : <div></div>}
                                {/*<Divider></Divider>*/}
                                {/*<Form*/}
                                {/*    layout='vertical'*/}
                                {/*    style={{justifyContent: 'center', paddingTop:"15px"}}*/}
                                {/*    name="basic"*/}
                                {/*    form={form}*/}
                                {/*    wrapperCol={{*/}
                                {/*        span: '75%',*/}
                                {/*    }}*/}
                                {/*    style={{*/}
                                {/*        width: '325px',*/}
                                {/*        verticalAlign: 'middle',*/}
                                {/*        maxWidth: '75%',*/}

                                {/*    }}*/}
                                {/*    initialValues={{*/}
                                {/*        remember: true,*/}
                                {/*    }}*/}
                                {/*    onFinish={onFinish}*/}
                                {/*    // onFinishFailed={}*/}
                                {/*    autoComplete="off"*/}
                                {/*>*/}
                                {/*    <Form.Item*/}
                                {/*        label="Тип запроса"*/}
                                {/*        name="type"*/}
                                {/*        rules={[*/}
                                {/*            {*/}
                                {/*                required: true,*/}
                                {/*                message: 'Выберите тип!',*/}
                                {/*            },*/}
                                {/*        ]}*/}
                                {/*    >*/}
                                {/*    <Select*/}
                                {/*        placeholder={"Выберите тип"}*/}
                                {/*        style={{width: "100%"}}*/}
                                {/*        dropdownMatchSelectWidth={false}*/}
                                {/*        allowClear={true}*/}
                                {/*        options={*/}

                                {/*            types.map((chatobject, indexch) => {*/}
                                {/*                return {value: chatobject.Id, label: chatobject.Name}*/}
                                {/*            })*/}
                                {/*        }*/}
                                {/*    />*/}
                                {/*</Form.Item>*/}
                                {/*    <Form.Item*/}
                                {/*        label="Комментарий"*/}
                                {/*        name="comment"*/}
                                {/*        rules={[*/}
                                {/*            {*/}
                                {/*                required: false,*/}
                                {/*                message: 'Введите название!',*/}
                                {/*            },*/}
                                {/*        ]}*/}
                                {/*    >*/}
                                {/*        <Input/>*/}
                                {/*    </Form.Item>*/}

                                {/*    <Form.Item*/}
                                {/*        wrapperCol={{*/}

                                {/*            span: 30,*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        <Button type="primary" htmlType="submit" size='large'>*/}
                                {/*            Закрыть запрос*/}
                                {/*        </Button>*/}
                                {/*    </Form.Item>*/}
                                {/*</Form>*/}
                            </Flex>
                        </Card>
                    ))}
                </Space>


            </Flex>
            <Flex justify={"center"} style={{padding: "45px"}}>

            </Flex>

        </div>
    );

}