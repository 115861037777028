import {Component, useEffect, useState} from "react";
import CountUp from 'react-countup';
import {Button, Card, Flex, Pagination, Row, Typography, Space, Progress, message, Statistic} from "antd";
import Apiurl from "../ConstURL";

import {
    CheckOutlined, CloseOutlined,
    FileExcelOutlined, MinusOutlined,
} from '@ant-design/icons';

import Header from "../components/Header";
import {useAuth} from "../funcs/Authcontext";



export default function Mainpage() {

    const [statics, Set_statics] = useState({
        "Open_tickets": 0,
        "Closed_tickets": 0,
        "Inwork_tickets": 0
    })



    const formatter = (value: number) => <CountUp end={value} separator=" " />;

    useEffect(()=>{
        fetch(Apiurl + "/tickets/all/stat?" + new URLSearchParams({
            token: localStorage.getItem("token")
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Status)
                Set_statics(data.Data[0])
            })
    },[]);




    return (<div>
        <Header/>
        <Flex justify={"center"} style={{paddingTop:"25px"}}>
            <Space type="flex" size='large' justify="center" align="middle" direction="vertical" style={{padding: "20px"}}>
                <Card>
                    <Flex gap={50}>
                        <Statistic title="Закрытых" value={statics.Closed_tickets} valueStyle={{color:"#47ff00"}} formatter={formatter} />
                        <Statistic title="В работе" value={statics.Inwork_tickets} valueStyle={{color:"#ff9201"}} formatter={formatter} />
                        <Statistic title="Ожидают ответа" value={statics.Open_tickets} valueStyle={{color:"#ff3c3c"}} formatter={formatter} />
                    </Flex>
                </Card>

            </Space>


        </Flex>


        </div>
    );

}