import {Component, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom"
import {
    Input,
    Card,
    Image,
    Flex,
    Pagination,
    Row,
    Typography,
    Space,
    Progress,
    message,
    Collapse,
    Select,
    Tag, Statistic, Switch, Button
} from "antd";
import Apiurl from "../ConstURL";
import {format} from 'date-fns'

import {
    CheckOutlined, CloseOutlined,
    FileExcelOutlined, MinusOutlined,
} from '@ant-design/icons';

import Header from "../components/Header";
import {useAuth} from "../funcs/Authcontext";
import CountUp from "react-countup";

function makeHumanReadable(num, singular) {
    return num > 0
        ? num + (num === 1 ? ` ${singular} ` : ` ${singular} `)
        : '';
}

function toDaysMinutesSeconds(totalSeconds) {
    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const days = Math.floor(totalSeconds / (3600 * 24));

    const secondsStr = makeHumanReadable(seconds, 'сек.');
    const minutesStr = makeHumanReadable(minutes, 'мин.');
    const hoursStr = makeHumanReadable(hours, 'ч.');
    const daysStr = makeHumanReadable(days, 'д.');

    return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(/,\s*$/, '');
}
export default function Worker() {
    const Navigate = useNavigate()
    const [workers_delete, Set_workers_delete] = useState(false)

    const [workers_massive, Set_workers_massive] = useState([])
    const [worker_static1, Set_worker_static1] = useState({
        "Open_tickets": 0,
        "Closed_tickets": 0,
        "Inwork_tickets": 0,
        "Average_time_done": 0
    })
    const formatter = (value: number) => <CountUp end={value} separator=" " />;
    let params = useParams()

    const DeleteElementYess = ()=>{
        fetch(Apiurl + "/worker/delete?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            id: params.workerid,

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Status)
                if (data.Status){
                    message.success("Пользователь удален!")
                    Navigate('/workers')
                }else {
                    message.error("Ошибка!")
                }
            })

        message.success("Пользователь создан!", 9)
    }

    const DeleteElementYesorNo = () => {
        Set_workers_delete(!workers_delete)
    }

    const onChangeBanned_status = (text) => {

        fetch(Apiurl + "/worker/ban?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            id: params.workerid,
            ban: (text ? "1" : "0")

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Status)
                Set_workers_massive(data.Data)
            })



    };





    useEffect(() => {
        console.log(params.workerid)


        fetch(Apiurl + "/worker/find/id?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            id: params.workerid,

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                Set_workers_massive(data.Data)
                console.log(data.Data)
            })

        fetch(Apiurl + "/worker/statistics/id?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            id: params.workerid,

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                Set_worker_static1(data.Data[0])
            })


    },[]);


    return (<div>
            <Header/>
            <Flex vertical={false} justify={"center"} style={{paddingTop: "25px"}}>

                <Space type="flex" size='large' justify="center" align="middle" direction="vertical"
                       style={{padding: "20px"}}>

                    {workers_massive.map((worker, index) => (
                        <Card title={worker.Username} style={{width: "85vw"}} >
                            <Flex vertical={true} gap={"small"}>
                                <div><b>ID воркера:</b> {worker.Id}</div>

                                <div><b>Telegram:</b> <a
                                    href={"https://t.me/" + worker.Telegram_username}>@{worker.Telegram_username}</a> ({worker.Telegram_id})</div>

                                <Flex style={{paddingTop:45}} justify={"center"} gap={50}>
                                    <Statistic title="Закрытых" value={worker_static1.Closed_tickets} valueStyle={{color:"#47ff00"}} formatter={formatter} />
                                    <Statistic title="В работе" value={worker_static1.Inwork_tickets} valueStyle={{color:"#ff9201"}} formatter={formatter} />
                                    {/*<Statistic title="Ожидают ответа" value={worker_static1.Open_tickets} valueStyle={{color:"#ff3c3c"}} formatter={formatter} />*/}
                                </Flex>
                                <Flex style={{paddingTop:25, paddingBottom:25}}justify={"center"} gap={50}>
                                    <Statistic  title="Среднее время закрытия тикета" value={toDaysMinutesSeconds(worker_static1.Average_time_done)} valueStyle={{color:"#ffffff"}} />
                                </Flex>

                                <Flex justify={"space-between"} style={{paddingTop:15}} vertical={false}>
                                <Flex gap={"small"} style={{width:150, paddingBottom:0}}>
                                    <div>Заблокирован: </div>
                                    <Switch  value={worker.Banned} onClick={onChangeBanned_status} ></Switch>
                                </Flex>

                                    {(workers_delete) ? <Space ><Button shape={"circle"} onClick={(e) => DeleteElementYess()} icon={<CheckOutlined />}></Button> <Button type={"primary"} shape={"circle"} icon={<CloseOutlined />} danger onClick={(e) =>DeleteElementYesorNo()}></Button></Space> : <Button style={{width:"225px"}} onClick={(e) =>DeleteElementYesorNo()} type={"primary"} danger>Удалить пользователя</Button>}

                                </Flex>



                                 {(worker.Banned) ? <Tag  color={"red"}>ЗАБЛОКИРОВАН</Tag> : ""}

                            </Flex>
                        </Card>
                    ))}
                </Space>


            </Flex>


        </div>
    );

}