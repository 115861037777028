import {Component, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom"
import {
    Input,
    Card,
    Image,
    Flex,
    Pagination,
    Row,
    Typography,
    Space,
    Progress,
    message,
    Collapse,
    Select,
    Tag, Button
} from "antd";
import Apiurl from "../ConstURL";
import Hosturl from "../ConstURL";

import {format} from 'date-fns'

import {
    CheckOutlined, CloseOutlined,
    FileExcelOutlined, MinusOutlined, PlusCircleOutlined,
} from '@ant-design/icons';

import Header from "../components/Header";
import {useAuth} from "../funcs/Authcontext";



export default function Mainpage() {
    let params = useParams()
    const Navigate = useNavigate()
    const [workers_massive, Set_workers_massive] = useState([])



    const [pagenum, set_pagenum] = useState(1)




    useEffect(() => {
        fetch(Apiurl + "/worker/find/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: 0,
            limit: 100,

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                Set_workers_massive(data.Data)
                console.log(data.Data)
            })


    },[]);


    return (<div>
            <Header/>
            <Flex justify={"center"} style={{paddingTop:25}}>
            <Flex  style={{ width: "85vw",flexDirection: "row-reverse", color:"White"}}><Button type='primary' onClick={(e) => Navigate('/addworker')} icon={<PlusCircleOutlined/>}> Добавить
                Воркера</Button></Flex>
            </Flex>
            <Flex vertical={false} justify={"center"} style={{paddingTop: "25px"}}>

                <Space type="flex" size='large' justify="center" align="middle" direction="vertical"
                       style={{padding: "20px"}}>

                    {workers_massive.map((worker, index) => (
                        <Card title={<a href={"/worker/"+worker.Id}>{worker.Username}</a>} style={{width: "75vw"}} >
                            <Flex vertical={true} gap={"small"}>
                                <div><b>ID воркера:</b> {worker.Id}</div>

                                <div><b>Telegram:</b> <a
                                    href={"https://t.me/" + worker.Telegram_username}>@{worker.Telegram_username}</a> ({worker.Telegram_id})</div>




                                {(worker.Banned) ? <Tag color={"red"}>ЗАБЛОКИРОВАН</Tag> : ""}

                            </Flex>
                        </Card>
                    ))}
                </Space>


            </Flex>


        </div>
    );

}