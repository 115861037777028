import {Component, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom"
import {
    Input,
    Card,
    Image,
    Flex,
    Pagination,
    Row,
    Typography,
    Space,
    Progress,
    message,
    Collapse,
    Select,
    Tag, Button, Statistic
} from "antd";
import Apiurl from "../ConstURL";
import Hosturl from "../ConstURL";

import {format} from 'date-fns'

import {
    CheckOutlined, CloseOutlined,
    FileExcelOutlined, MinusOutlined, PlusCircleOutlined,
} from '@ant-design/icons';

import Header from "../components/Header";
import {useAuth} from "../funcs/Authcontext";


export default function Mainpage() {
    let params = useParams()
    const Navigate = useNavigate()
    const [Chats_massive, Set_Chats_massive] = useState([])


    function DeleteElementYess(id) {
        const idx = Chats_massive.findIndex((el) => el.Id === id)
        let newusers = Chats_massive.slice()
        newusers[idx].DeleteYesorNo = !newusers[idx].DeleteYesorNo
        Set_Chats_massive(newusers)

        fetch(Apiurl + "/tickets/delete/type?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticketid: id
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.Status) {
                    let newusers = Chats_massive.slice()
                    console.log(newusers)
                    newusers.splice(idx, 1)
                    console.log(newusers)
                    Set_Chats_massive(newusers)
                    message.success("Тип удален!")
                } else {
                    message.error("Тип не удален!")
                }

            })


    }

    function DeleteElementYesorNo(id) {

        const idx = Chats_massive.findIndex((el) => el.Id === id)
        let newusers = Chats_massive.slice()
        console.log(newusers[idx].DeleteYesorNo)
        newusers[idx].DeleteYesorNo = !newusers[idx].DeleteYesorNo
        console.log(newusers[idx].DeleteYesorNo)
        Set_Chats_massive(newusers)


    }

    useEffect(() => {
        fetch(Apiurl + "/tickets/get/type?" + new URLSearchParams({
            token: localStorage.getItem("token"),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                Set_Chats_massive(data.Data)
                //console.log(data.Data)
            })


    }, []);


    return (<div>
            <Header/>
            <Flex justify={"center"} style={{paddingTop: 25}}>
                <Flex style={{width: "45vw", flexDirection: "row-reverse", color: "White"}}><Button type='primary'
                                                                                                    onClick={(e) => Navigate('/tickettypes/add')}
                                                                                                    icon={
                                                                                                        <PlusCircleOutlined/>}> Добавить тип</Button></Flex>
            </Flex>
            <Flex vertical={false} justify={"center"} style={{paddingTop: "25px"}}>

                <Space type="flex" size='large' justify="center" align="middle" direction="vertical"
                       style={{padding: "20px"}}>

                    {Chats_massive.map((Chat, index) => (
                        <Card title={Chat.Name} style={{width: "25vw"}} extra={
                            (Chat.DeleteYesorNo ?
                                <Space><Button shape={"circle"} onClick={(e) => DeleteElementYess(Chat.Id)}
                                               icon={<CheckOutlined/>}></Button> <Button type={"primary"}
                                                                                         shape={"circle"}
                                                                                         icon={<CloseOutlined/>} danger
                                                                                         onClick={(e) => DeleteElementYesorNo(Chat.Id)}></Button></Space> :
                                <Button type={"primary"} danger shape={"circle"} icon={<MinusOutlined/>}
                                        onClick={(e) => DeleteElementYesorNo(Chat.Id)}></Button>)
                        }>
                            Id: {Chat.Id}
                        </Card>
                    ))}
                </Space>


            </Flex>


        </div>
    );

}