import logo from './logo.svg';
import './App.css';
import {ConfigProvider, Layout, theme} from "antd";
import {AuthProvider} from "./funcs/Authcontext";
import {Header} from "antd/es/layout/layout";
import {Route, Routes, useParams} from "react-router-dom";
import PrivateRoutes1 from "./funcs/CheckAuth";
import Authpage from "./pages/Authpage";
import Mainpage from "./pages/Mainpage";
import Tickets from "./pages/Tickets";
import Workers from "./pages/Workers";
import Worker from "./pages/Worker";
import Createuser from "./pages/Createuser";
import Chats from "./pages/Chats";
import Chats_contractors from "./pages/Chats_contractors";
import TicketTypes from "./pages/TicketTypes";
import AddTicketType from "./pages/AddTicketType";
import Contracts_Page from "./pages/Contracts_Page";
import ruRu from 'antd/locale/ru_RU';

import {useState} from "react";

function App() {


    return (
        <ConfigProvider
            locale={ruRu}
            theme={{

                algorithm: theme.darkAlgorithm,
                "token": {

                    "colorPrimary": "#a017ff",
                    "colorInfo": "#a017ff",
                    "fontSize": 16
                },

            }}
        >
            <Layout style={{minHeight: "100vh"}}>
                <AuthProvider>
                    <Routes>
                        <Route path='/ticket/:ticketid/:tickettoken' element={<Contracts_Page/>}/>
                        <Route path="/login" element={<Authpage/>}/>

                        <Route element={<PrivateRoutes1/>}>
                            <Route exact path='/*' element={<Mainpage/>}/>
                            <Route exact path='/tickets' element={<Tickets/>}/>
                            <Route path='/workers' element={<Workers/>}/>
                            <Route path='/worker/:workerid' element={<Worker/>}/>
                            <Route path='/addworker' element={<Createuser/>}/>
                            <Route path='/chats' element={<Chats/>}/>
                            <Route path='/chats_contractors' element={<Chats_contractors/>}/>
                            <Route path='/tickettypes' element={<TicketTypes/>}/>
                            <Route path='/tickettypes/add' element={<AddTicketType/>}/>

                            {/*<Route path='/transport' element={<Transport/>}/>*/}
                        </Route>
                    </Routes>

                </AuthProvider>
            </Layout>
        </ConfigProvider>
    );
}

export default App;
